import React, { useEffect, useState } from 'react';
import { Polar } from 'react-chartjs-2';
import { SettingsStore } from '../../stores';

export const flavorNotes = {
  Coffee: [
    { label: 'Espresso', value: 'espresso', selected: false },
    { label: 'Blonde Roast', value: 'blonde_roast', selected: false },
    { label: 'Dark Roast', value: 'dark_roast', selected: false },
    { label: 'Coffee With Cream', value: 'coffee_cream', selected: false },
    { label: 'Coffee Beans', value: 'coffee_beans', selected: false },
  ],
  Nutty: [
    { label: 'Almond', value: 'almond', selected: false },
    { label: 'Cashew', value: 'cashew', selected: false },
    { label: 'Peanut', value: 'peanut', selected: false },
    { label: 'Chestnut', value: 'chestnut', selected: false },
    { label: 'Walnut', value: 'walnut', selected: false },
  ],
  Fruit: [
    { label: 'Cherry', value: 'cherry', selected: false },
    { label: 'Citrus', value: 'citrus', selected: false },
    { label: 'Plum', value: 'plum', selected: false },
    { label: 'Orange Zest', value: 'orange_zest', selected: false },
    { label: 'Raisin', value: 'raisin', selected: false },
  ],
  Sweet: [
    { label: 'Vanilla', value: 'vanilla', selected: false },
    { label: 'Caramel', value: 'caramel', selected: false },
    { label: 'Chocolate', value: 'chocolate', selected: false },
    { label: 'Toffee', value: 'toffee', selected: false },
    { label: 'Molasses', value: 'molasses', selected: false },
  ],
  'Spices & Herbs': [
    { label: 'Black Pepper', value: 'black_pepper', selected: false },
    { label: 'Cayenne', value: 'cayenne', selected: false },
    { label: 'Cinnamon', value: 'cinnamon', selected: false },
    { label: 'Clove', value: 'clove', selected: false },
    { label: 'Nutmeg', value: 'nutmeg', selected: false },
  ],
  'Wood & Vegetation': [
    { label: 'Cedar / Oak', value: 'wood', selected: false },
    { label: 'Grass', value: 'grass', selected: false },
    { label: 'Cereal / Grain', value: 'cereal', selected: false },
    { label: 'Hay', value: 'hay', selected: false },
    { label: 'Tea', value: 'tea', selected: false },
  ],
  Natural: [
    { label: 'Earth', value: 'earth', selected: false },
    { label: 'Ash', value: 'ash', selected: false },
    { label: 'Must', value: 'must', selected: false },
    { label: 'Leather', value: 'leather', selected: false },
    { label: 'Barnyard', value: 'barnyard', selected: false },
  ],
};

export const flavorColors = {
  Coffee: {
    color: '#efefef',
    backgroundColor: '#774c2a',
  },
  Nutty: {
    color: '#2b2b2b',
    backgroundColor: '#c4996c',
  },
  Fruit: {
    color: '#efefef',
    backgroundColor: '#954456',
  },
  Sweet: {
    color: '#2b2b2b',
    backgroundColor: '#c3b59b',
  },
  'Spices & Herbs': {
    color: '#efefef',
    backgroundColor: '#c01d2e',
  },
  'Wood & Vegetation': {
    color: '#efefef',
    backgroundColor: '#8c9345',
  },
  Natural: {
    color: '#efefef',
    backgroundColor: '#726658',
  },
};

const flavorNoteMapping = {};
Object.keys(flavorNotes).forEach((flavorSection) => {
  const flavorBreakdown = flavorNotes[flavorSection];
  flavorBreakdown.forEach((flavor) => {
    flavorNoteMapping[flavor.value] = {
      label: flavor.label,
      section: flavorSection,
    };
  });
});

export const sectionForFlavor = (flavor) => flavorNoteMapping[flavor].section;

function FlavorNotesChart(props) {
  const [noteData, setNoteData] = useState([0, 0, 0, 0, 0, 0]);
  const [options, setOptions] = useState(null);
  const darkMode = SettingsStore.useState((s) => s.darkMode);

  const flavorKeys = Object.keys(flavorNotes);

  const data = {
    datasets: [{
      data: noteData,
      backgroundColor: Object.keys(flavorColors).map((flavor) => flavorColors[flavor].backgroundColor),
      borderColor: darkMode ? '#17191d' : '#ffffff',
      label: '', // for legend
    }],
    labels: flavorKeys,
  };

  useEffect(() => {
    console.log('Selected flavor notes in chart:');
    console.log(props.notes);
    if (props.notes) {
      const tempNoteData = {};
      Object.keys(props.notes).forEach((noteValue) => {
        // console.log(noteValue);
        // console.log(flavorNoteMapping[noteValue]);
        if (props.notes[noteValue] > 0) {
          if (flavorNoteMapping[noteValue]) {
            const count = tempNoteData[sectionForFlavor(noteValue)] || 0;
            tempNoteData[sectionForFlavor(noteValue)] = count + 1;
          }
        }
      });
      // console.log('Temp note data');
      // console.log(tempNoteData);
      setNoteData(flavorKeys.map((section) => (tempNoteData[section] || 0)));
    }

    setOptions({
      responsive: true,
      maintainAspectRatio: false,
      // aspectRatio: 2,
      legend: {
        position: typeof props.legend === 'string' && props.legend, // FIXME The scale is off when this gets added - why won't it go full width and adjust the height?
        display: props.legend !== false,
      },
      // FIXME Do we want these on desktop? Not sure they work on mobile - maybe modal popup? Or just allow tapping
      //  on the chart to go full screen?
      // tooltips: {
      //   callbacks: {
      //     label: function (tooltipItem, data) {
      //       let dataset = data.datasets[tooltipItem.datasetIndex];
      //       let index = tooltipItem.index;
      //       return dataset.labels[index];
      //     },
      //   },
      // },
      scale: {
        ticks: {
          beginAtZero: true,
          // max: 5,
          min: 0,
          stepSize: 1,
          fontColor: darkMode ? '#efefef' : '#2b2b2b',
        },
        gridLines: {
          color: darkMode ? '#656565' : '#dfdfdf',
        },
      },
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
      // See https://stackoverflow.com/questions/36403477/how-to-change-background-color-of-labels-in-line-chart-from-chart-js to fix the label bg color in dark mode
      plugins: [{
        afterDraw(chart, options) {},
      }],
    });
  }, [props.notes]);

  return <Polar data={data} height={props.height} width={props.width} options={options} />;
}

export default FlavorNotesChart;
