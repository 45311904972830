import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import './style.css';
import FancyBox from '../fancybox';
import Icon from '../Icon';
import { Chip } from '@material-ui/core';
import { LazyImage } from 'react-lazy-images';

// FIXME If withEditor flag is true, also allow drag and drop for ordering - https://github.com/atlassian/react-beautiful-dnd
class ImageGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countFrom: props.countFrom > 0 && props.countFrom < 5 ? props.countFrom : 5,
      conditionalRender: false,
    };

    if (props.countFrom <= 0 || props.countFrom > 5) {
      console.warn('countFrom is limited to 5!');
    }
  }

  renderOne(heightPercentage = 50, offset = 0) {
    const { images, thumbnailImages, lazyImages, direction } = this.props;
    const { countFrom } = this.state;
    const conditionalRender = direction === 'horizontal' && heightPercentage === 50;
    const overlay = images.length > countFrom && countFrom === 1 ? this.renderCountOverlay(true) : this.renderOverlay(conditionalRender ? 1 + offset : offset);

    return (
      <Container>
        <Row>
          <Col xs={12} md={12} className="border height-one background" style={{ paddingTop: `${heightPercentage}%`, overflow: 'hidden' }}>
            <LazyImage
              src={conditionalRender ? thumbnailImages[1 + offset] : thumbnailImages[offset]}
              // srcSet={conditionalRender ? lazyImages[1 + offset] : lazyImages[offset]}
              alt="No photo description available"
              placeholder={({ imageProps, ref }) => <img ref={ref} src={lazyImages ? (conditionalRender ? lazyImages[1 + offset] : lazyImages[offset]) : null} className="main-img img-blur" />}
              actual={({ imageProps }) => <img {...imageProps} className="main-img" />}
            />
            {overlay}
          </Col>
        </Row>
      </Container>
    );
  }

  renderTwo(force = false) {
    const { images, thumbnailImages, lazyImages, direction } = this.props;
    const { countFrom } = this.state;

    if (direction === 'horizontal' && !force) {
      const conditionalRender = [3, 4].includes(images.length) || (images.length > +countFrom && [3, 4].includes(+countFrom));
      const offset = force ? 2 : 0;
      const overlay = images.length > countFrom && [3, 4].includes(+countFrom) ? this.renderCountOverlay(true) : this.renderOverlay(conditionalRender ? 2 + offset : 1 + offset);

      return (
        <Container>
          <Row>
            <Col xs={12} md={12} className="border height-one background">
              <LazyImage
                src={conditionalRender ? thumbnailImages[1 + offset] : thumbnailImages[offset]}
                alt="No photo description available"
                placeholder={({ imageProps, ref }) => <img ref={ref} src={lazyImages ? (conditionalRender ? lazyImages[1 + offset] : lazyImages[offset]) : null} className="main-img img-blur" />}
                actual={({ imageProps }) => <img {...imageProps} className="main-img" />}
              />
              {this.renderOverlay(conditionalRender ? 1 + offset : offset)}
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} className="border height-one background">
              <LazyImage
                src={conditionalRender ? thumbnailImages[2 + offset] : thumbnailImages[1 + offset]}
                alt="No photo description available"
                placeholder={({ imageProps, ref }) => <img ref={ref} src={lazyImages ? (conditionalRender ? lazyImages[2 + offset] : lazyImages[1 + offset]) : null} className="main-img img-blur" />}
                actual={({ imageProps }) => <img {...imageProps} className="main-img" />}
              />
              {overlay}
            </Col>
          </Row>
        </Container>
      );
    }

    const offset = force ? 2 : 0;
    const conditionalRender = [3 + offset, 4 + offset].includes(images.length) || (images.length > +countFrom && [3 + offset, 4 + offset].includes(+countFrom));
    const overlay = images.length > countFrom && [2 + offset, 3 + offset].includes(+countFrom) ? this.renderCountOverlay(true) : this.renderOverlay(offset + conditionalRender ? 2 : 1);

    return (
      <Container>
        <Row>
          <Col xs={6} md={6} className="border height-two background" style={{ background: `url(${conditionalRender ? thumbnailImages[1 + offset] : thumbnailImages[offset]})`, imageOrientation: 'from-image' }}>
            {this.renderOverlay(conditionalRender ? 1 + offset : offset)}
          </Col>
          <Col xs={6} md={6} className="border height-two background" style={{ background: `url(${conditionalRender ? thumbnailImages[2 + offset] : thumbnailImages[1 + offset]})`, imageOrientation: 'from-image' }}>
            {overlay}
          </Col>
        </Row>
      </Container>
    );
  }

  renderThree(more) {
    const { images, thumbnailImages, lazyImages } = this.props;
    const { countFrom } = this.state;
    const overlay = !countFrom || countFrom > 5 || (images.length > countFrom && [4, 5].includes(+countFrom)) ? this.renderCountOverlay(true) : this.renderOverlay(conditionalRender ? 3 : 4);
    const conditionalRender = images.length === 4 || (images.length > +countFrom && +countFrom === 4);

    return (
      <Container>
        <Row>
          <Col xs={6} md={4} className="border height-three background">
            <LazyImage
              src={conditionalRender ? thumbnailImages[1] : thumbnailImages[2]}
              alt="No photo description available"
              placeholder={({ imageProps, ref }) => <img ref={ref} src={lazyImages ? (conditionalRender ? lazyImages[1] : lazyImages[2]) : null} className="main-img img-blur" />}
              actual={({ imageProps }) => <img {...imageProps} className="main-img" />}
            />
            {this.renderOverlay(conditionalRender ? 1 : 2)}
          </Col>
          <Col xs={6} md={4} className="border height-three background">
            <LazyImage
              src={conditionalRender ? thumbnailImages[2] : thumbnailImages[3]}
              alt="No photo description available"
              placeholder={({ imageProps, ref }) => <img ref={ref} src={lazyImages ? (conditionalRender ? lazyImages[2] : lazyImages[3]) : null} className="main-img img-blur" />}
              actual={({ imageProps }) => <img {...imageProps} className="main-img" />}
            />
            {this.renderOverlay(conditionalRender ? 2 : 3)}
          </Col>
          <Col xs={6} md={4} className="border height-three background">
            <LazyImage
              src={conditionalRender ? thumbnailImages[3] : thumbnailImages[4]}
              alt="No photo description available"
              placeholder={({ imageProps, ref }) => <img ref={ref} src={lazyImages ? (conditionalRender ? lazyImages[3] : lazyImages[4]) : null} className="main-img img-blur" />}
              actual={({ imageProps }) => <img {...imageProps} className="main-img" />}
            />
            {overlay}
          </Col>
        </Row>
      </Container>
    );
  }

  renderOverlay(index, showContainer = true) {
    const {
      images,
      hideOverlay,
      renderOverlay,
      overlayBackgroundColor,
      fancyBox,
      withEditor,
    } = this.props;
    const image = images[index];
    if (fancyBox) {
      return (
        <div>
          <FancyBox
            tagName="a"
            className="rui-gallery-item"
            href={image}
            closeExisting
            popupClassName="rui-popup"
            galleryId="profile-gallery"
          >
            {showContainer ? <div style={{ position: 'absolute', top: 0, height: '100%', width: '100%' }} /> : null}
          </FancyBox>
          {withEditor && (
            <span
              style={{ position: 'absolute', top: 8, left: 8 }}
              onClick={() => {
                if (typeof this.props.onChange === 'function') {
                  // console.log(`Editing ${image}`);
                  this.props.onEdit(index, image);
                }
              }}
            >
              <Chip label={<span>Edit</span>} />
            </span>
          )}
          {withEditor && (
            <Chip
              className="circle-chip"
              style={{
                position: 'absolute',
                top: 8,
                right: 8,
              }}
              onClick={() => {
                if (typeof this.props.onChange === 'function') {
                  this.props.onChange(images.filter((e) => e !== image));
                }
              }}
              label={<Icon name="x" />}
            />
          )}
        </div>
      );
    }

    if (hideOverlay) {
      return false;
    }

    return [
      <div key={`cover-${index}`} className="cover slide" style={{ backgroundColor: overlayBackgroundColor }} />,
      <div key={`cover-text-${index}`} className="cover-text visible slide animate-text" style={{ fontSize: '100%' }}>
        {renderOverlay(index)}
      </div>,
    ];
  }

  renderCountOverlay(more) {
    const { images } = this.props;
    const { countFrom } = this.state;
    const extra = images.length - (countFrom && countFrom > 5 ? 5 : countFrom);
    return more && (
      <FancyBox
        tagName="a"
        className="rui-gallery-item"
        href={images[countFrom - 1]}
        closeExisting
        popupClassName="rui-popup"
        galleryId="profile-gallery"
      >
        <div key="count-sub" className="cover-text visible" style={{ fontSize: '200%' }}><p>{`+${extra}`}</p></div>
      </FancyBox>
    );
  }

  renderRemainingOverlays() {
    const { images, countFrom } = this.props;
    // console.log('Rendering remaining overlays...');
    // console.log(images);
    // console.log(images.slice(countFrom + 1));
    return images.slice(countFrom + 1).map((_, index) => this.renderOverlay(index + countFrom, false));
  }

  render() {
    const { countFrom } = this.state;
    const { images, direction } = this.props;
    const imagesToShow = [...images];

    // console.log('Image grid images:');
    // console.log(images);

    if (countFrom && images.length > countFrom) {
      imagesToShow.length = countFrom;
    }

    if (direction === 'horizontal') {
      return (
        <div onClick={this.props.onClick}>
          <Row style={{ margin: 0 }}>
            <Col style={{ padding: 0 }}>
              {this.renderOne(imagesToShow.length === 1 ? 80 : 100)}
            </Col>
            <Col style={{ padding: 0 }}>
              {imagesToShow.length === 2 && this.renderOne(imagesToShow.length === 2 ? 100 : 50, 1)}
              {imagesToShow.length === 3 && this.renderTwo()}
              {imagesToShow.length >= 4 && this.renderOne()}
              {imagesToShow.length >= 4 && this.renderTwo(true)}
            </Col>
          </Row>
          {images.length > 5 && this.renderRemainingOverlays()}
        </div>
      );
    }
    return (
      <div className="grid-container" onClick={this.props.onClick}>
        {[1, 3, 4].includes(imagesToShow.length) && this.renderOne(imagesToShow.length === 1 ? 80 : 50)}
        {imagesToShow.length >= 2 && imagesToShow.length !== 4 && this.renderTwo()}
        {imagesToShow.length >= 4 && this.renderThree()}
        {images.length > 5 && this.renderRemainingOverlays()}
      </div>
    );
  }
}

ImageGrid.propTypes = {
  images: PropTypes.array.isRequired,
  thumbnailImages: PropTypes.array.isRequired,
  hideOverlay: PropTypes.bool,
  renderOverlay: PropTypes.func,
  overlayBackgroundColor: PropTypes.string,
  onClickEach: PropTypes.func,
  countFrom: PropTypes.number,
  direction: PropTypes.string,
  fancyBox: PropTypes.bool,
  onClick: PropTypes.func,
  withEditor: PropTypes.bool,
  onChange: PropTypes.func,
  onEdit: PropTypes.func,
};

ImageGrid.defaultProps = {
  hideOverlay: false,
  renderOverlay: () => '',
  overlayBackgroundColor: '#222222',
  onClickEach: null, // TODO Might want to add this back in case we want to allow comments on each individual photo
  countFrom: 5,
  direction: 'vertical',
  fancyBox: false,
  onClick: undefined,
  withEditor: false,
  onChange: null,
  onEdit: null,
};

export default ImageGrid;
