import './style.scss';
import '@fancyapps/fancybox';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, InputGroup, InputGroupAddon } from 'reactstrap';
import { connect } from 'react-redux';
import { isMobile } from 'mobile-device-detect';
import { Button as MaterialButton, Checkbox, MenuItem, Select } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Drawer from '@material-ui/core/Drawer';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Geolocation from 'react-geolocation';
import { Geolocation as NativeGeolocation } from '@capacitor/geolocation';
import Alert from '@material-ui/lab/Alert';
import GeoLocationSearch from '../geolocation-search';
import MapListingContent from './content';
import Icon from '../Icon';
import { config } from '../../settings';
import { useNearbyFilters } from '../../utils/useSettings';
import whenAvailable from '../../utils/whenAvailable';
import GooglePlacesAutocomplete from 'react-google-autocomplete';
import { Transducer } from '../../utils/transducer';
import { language, messages } from '../../utils/localeUtils';
import safeAreaInsets from 'safe-area-insets';
import FormControl from '@material-ui/core/FormControl';
import { useLocation } from '../../utils/hooks/useLocation';

export const mapStylesLight = [
  {
    stylers: [
      {
        saturation: -100,
      },
      {
        gamma: 1,
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi.business',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi.business',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi.place_of_worship',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi.place_of_worship',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'water',
    stylers: [
      {
        visibility: 'on',
      },
      {
        saturation: 50,
      },
      {
        gamma: 0,
      },
      {
        hue: '#50a5d1',
      },
    ],
  },
  {
    featureType: 'administrative.neighborhood',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#333333',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text',
    stylers: [
      {
        weight: 0.5,
      },
      {
        color: '#333333',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'labels.icon',
    stylers: [
      {
        gamma: 1,
      },
      {
        saturation: 50,
      },
    ],
  },
];
export const mapStylesDark = [
  {
    featureType: 'administrative',
    elementType: 'all',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#1d1d1d',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#626060',
      },
      {
        weight: '1',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#191414',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'all',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'administrative.province',
    elementType: 'all',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'administrative.neighborhood',
    elementType: 'all',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        color: '#191414',
      },
      {
        weight: '1',
      },
      {
        gamma: '1',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
      {
        color: '#24262A',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'on',
      },
      {
        weight: '0.97',
      },
      {
        color: '#7f8c8d',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text',
    stylers: [
      {
        weight: '2',
      },
      {
        visibility: 'on',
      },
      {
        color: '#696464',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#24262A',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'all',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#232222',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'all',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#000000',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        color: '#00202c',
      },
      {
        visibility: 'on',
      },
    ],
  },
];

const mileInMeters = 1609.34;

const radiusOptions = [{
  label: '5mi',
  value: 5 * mileInMeters,
}, {
  label: '25mi',
  value: 25 * mileInMeters,
}, {
  label: '50mi',
  value: 50 * mileInMeters,
}, {
  label: '100mi',
  value: 100 * mileInMeters,
}, {
  label: '200mi',
  value: 200 * mileInMeters,
}];

const sortOptions = [{
  label: 'Distance',
  value: 'distance',
}, {
  label: 'Name',
  value: 'name',
}, {
  label: 'Rating',
  value: 'rating',
}];

function MapListing(props) {
  const location = useLocation();
  const [venues, setVenues] = useState([]);
  const [permissionUpdated, setPermissionUpdated] = useState(false);
  const [nearbyFilters, setNearbyFilters] = useNearbyFilters({
    selectedRadiusOption: radiusOptions[1].value,
    selectedSortOption: sortOptions[0].value,
    canBuy: true,
    canSmoke: true,
  });
  console.log('nearbyFilters:');
  console.log(nearbyFilters);
  const [state, setState] = useState({
    loading: true,
    activeTab: 0,
    selectedVenue: null,
    selectedZoom: isMobile ? 9 : 10,
    showInfoWindow: false,
    mapMarkers: {},
    showBottomSheet: false,
  });
  const [nativeError, setNativeError] = useState(false);
  const [coordinates, setCoordinates] = useState(null); // FIXME What if for some reason we can't get them? Error message? Prompt for permissions?
  const [selectedLocation, setSelectedLocation] = useState(null); // Geolocation object from Google Maps

  const logUserLocation = (latitude, longitude) => {
    whenAvailable('analytics', () => {
      if (window.analytics) {
        const { auth } = props;
        const { user } = auth;
        if (latitude && longitude && user && user.id) {
          // FIXME This doesn't seem to be storing the lat/lng along with these
          window.analytics.track('User Locations', {
            context_latitude: latitude,
            context_longitude: longitude,
            user_id: user.id,
          });
        }
      }
    });
  };

  /**
   * @deprecated Use Capacitor exclusively once Android issue is fixed
   */
  const updateNativeCoordinates = ({ data }) => {
    if (data && data.type === 'coordinates_updated' && data.coordinates) {
      setCoordinates(data.coordinates);
      setPermissionUpdated(true);
      setState({
        loading: true,
      });
    } else if (data.type === 'on_location_error') {
      setNativeError(true);
      setState({
        loading: true,
      });
    }
  };

  useEffect(() => {
    const mobileSearchBar = document.getElementById('main-mobile-search-bar');
    if (mobileSearchBar) {
      const originalBoxShadow = mobileSearchBar.style.boxShadow;
      mobileSearchBar.style.boxShadow = 'none';
      return () => {
        mobileSearchBar.style.boxShadow = originalBoxShadow;
      };
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', updateNativeCoordinates);
    if (isMobile) {
      jQuery('#mobile-search-bar').css('box-shadow', 'none');
    }
    return () => {
      window.removeEventListener('message', updateNativeCoordinates);
      if (isMobile) {
        jQuery('#mobile-search-bar').css('box-shadow', 'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px');
      }
    };
  }, []);

  useEffect(() => {
    console.log('Coordinates updated...');
    if (coordinates && coordinates.latitude && coordinates.longitude) {
      logUserLocation(coordinates.latitude, coordinates.longitude);
      updateVenues();
    }
  }, [coordinates]);

  useEffect(() => {
    console.log('Coordinates updated...');
    if (props.coords && props.coords.latitude && props.coords.longitude) {
      setCoordinates({
        latitude: props.coords.latitude,
        longitude: props.coords.longitude,
      });
    }
  }, [props.coords]);

  useEffect(() => {
    console.log('Coordinates updated...');
    if (location && location.latitude && location.longitude) {
      setCoordinates({
        latitude: location.latitude,
        longitude: location.longitude,
      });
    }
  }, [location]);

  useEffect(() => {
    console.log('Coordinates updated...');
    if (selectedLocation && selectedLocation.latitude && selectedLocation.longitude) {
      setCoordinates({
        latitude: selectedLocation.latitude,
        longitude: selectedLocation.longitude,
      });
    }
  }, [selectedLocation]);

  const updateVenues = (force) => {
    console.log('Updating venues...');
    const { selectedRadiusOption, selectedSortOption, canSmoke, canBuy } = nearbyFilters;
    const { coords } = props; // Accurate location coordinates

    let { latitude, longitude } = coordinates;

    if (selectedLocation && selectedLocation.geometry && selectedLocation.geometry.location) {
      console.log('Using selectedLocation:');
      console.log(selectedLocation);
      latitude = selectedLocation.geometry.location.latitude;
      longitude = selectedLocation.geometry.location.longitude;
    } else if (coords) {
      console.log('Using coords:');
      console.log(coords);
      latitude = coords.latitude;
      longitude = coords.longitude;
    } else {
      console.log('Using coordinates:');
      console.log(coordinates);
    }

    const { latitude: lastLatitude, longitude: lastLongitude } = Transducer.getPreference('last-location') || {};

    console.log('Last location:');
    console.log({ latitude: lastLatitude, longitude: lastLongitude });

    if (latitude !== lastLatitude || longitude !== lastLongitude || force) {
      console.log('Getting new results for location:');
      console.log(`${latitude}, ${longitude}`);

      axios.get(`${config.apiEndPoint}/venues/nearby`, {
        params: {
          limit: 30,
          latitude,
          longitude,
          radius: selectedRadiusOption,
          sort: selectedSortOption,
          smoke: canSmoke ? true : undefined,
          buy: canBuy ? true : undefined,
        },
      }).then((response) => {
        console.log('Venues:');
        console.log(response.data);

        // Only store the current location, not searched locations
        if (!selectedLocation) {
          Transducer.setPreference('last-location', { latitude, longitude });
          Transducer.setPreference('last-venue-search', response.data);
        }
        setState({
          loading: false,
        });
        setVenues(response.data);
      }).catch((err) => {
        console.log(err);
        setState({
          loading: false,
        });
      });
    } else {
      console.log('Pulling results from cache...');
      setState({
        loading: false,
      });
      setVenues(Transducer.getPreference('last-venue-search'));
    }
  };

  const toggleBottomSheet = () => {
    console.log('Showing bottom sheet...');
    setState({ showBottomSheet: !state.showBottomSheet });
  };

  const handleFilterChange = (type) => (event, value) => {
    if (isMobile) {
      setNearbyFilters((prevFilters) => ({
        ...prevFilters,
        [type]: value,
      }));
    } else {
      setNearbyFilters((prevFilters) => ({
        ...prevFilters,
        [type]: event.target.value,
      }));
    }
  };

  const handleCheckChange = (type) => (event) => {
    setNearbyFilters((prevFilters) => ({
      ...prevFilters,
      [type]: event.target.checked,
    }));
  };

  const renderOptions = () => {
    if (isMobile) {
      return (
        <div>
          <div>Search Radius</div>
          <ToggleButtonGroup
            value={nearbyFilters.selectedRadiusOption}
            exclusive
            onChange={handleFilterChange('selectedRadiusOption')}
            aria-label="Search Radius Options"
            style={{ marginBottom: 14 }}
          >
            {radiusOptions.map((item) => (
              <ToggleButton value={item.value} aria-label={item.label}>
                {item.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <div>Sort By</div>
          <ToggleButtonGroup
            value={nearbyFilters.selectedSortOption}
            exclusive
            onChange={handleFilterChange('selectedSortOption')}
            aria-label="Sort Options"
            style={{ marginBottom: 14 }}
          >
            {sortOptions.map((item) => (
              <ToggleButton value={item.value} aria-label={item.label}>
                {item.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox checked={nearbyFilters.canBuy} />
              }
              onChange={handleCheckChange('canBuy')}
              label="Can Buy"
            />
            <FormControlLabel
              control={
                <Checkbox checked={nearbyFilters.canSmoke} />
              }
              onChange={handleCheckChange('canSmoke')}
              label="Can Smoke"
            />
          </FormGroup>
          {/* TODO Include checkbox options for most common amenities, use collapse to show full list */}
          <Button
            size="lg"
            block
            color="primary"
            style={{ display: 'inline-block' }}
            onClick={() => {
              updateVenues(true);
              toggleBottomSheet();
            }}
          >
            {'Apply'}
          </Button>
        </div>
      );
    }
    return (
      <div>
        <div style={{ display: 'flex', textAlign: 'right', padding: 20, marginLeft: 'auto', float: 'right' }}>
          <div style={{ marginRight: 24, display: 'flex', alignItems: 'center', marginTop: -6 }}>
            <span style={{ marginRight: 10 }}>Search Radius</span>
            <FormControl size="small">
              <Select
                variant="outlined"
                value={nearbyFilters.selectedRadiusOption}
                onChange={handleFilterChange('selectedRadiusOption')}
                selectedMenuItemStyle={{ color: '#d5c196' }}
                style={{ maxHeight: 34 }}
              >
                { radiusOptions.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ marginRight: 24, display: 'flex', alignItems: 'center', marginTop: -6 }}>
            <span style={{ marginRight: 10 }}>Sort By</span>
            <FormControl size="small">
              <Select
                variant="outlined"
                value={nearbyFilters.selectedSortOption}
                onChange={handleFilterChange('selectedSortOption')}
                selectedMenuItemStyle={{ color: '#d5c196' }}
                style={{ maxHeight: 34 }}
              >
                { sortOptions.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ marginRight: 24, marginTop: 8 }}>
            <FormGroup row style={{ display: 'inline' }}>
              <FormControlLabel
                control={
                  <Checkbox checked={nearbyFilters.canBuy} />
                }
                onChange={handleCheckChange('canBuy')}
                label="Can Buy"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={nearbyFilters.canSmoke} />
                }
                onChange={handleCheckChange('canSmoke')}
                label="Can Smoke"
              />
            </FormGroup>
          </div>
          <div style={{ marginTop: 8 }}>
            <Button
              color="primary"
              style={{ display: 'inline-block' }}
              onClick={() => {
                updateVenues(true);
                toggleBottomSheet();
              }}
            >
              {'Apply'}
            </Button>
          </div>
        </div>
        <div className="clearfix" />
      </div>
    );
  };

  const renderFilterSheet = () => (
    <div>
      <Drawer
        anchor="bottom"
        open={state.showBottomSheet}
        // onClick={toggleBottomSheet}
        onClose={toggleBottomSheet}
      >
        <div
          role="presentation"
          // onClick={toggleBottomSheet}
          // onKeyDown={toggleBottomSheet}
          style={{ padding: 20 }}
        >
          { renderOptions() }
        </div>
      </Drawer>
    </div>
  );

  const insetTop = safeAreaInsets.top;

  return (
    <Geolocation
      lazy
      onSuccess={(position) => {
        console.log('Changed position:');
        console.log(position);
        // FIXME How to clear GeoLocationSearch text input?
        setState({
          loading: true,
        });
        setCoordinates(position.coords);
        setSelectedLocation(null);
        setPermissionUpdated(true);
        logUserLocation(position.coords.latitude, position.coords.longitude);
        if (window.analytics) {
          const { auth } = props;
          const { user } = auth;
          if (position.coords && user && user.id) {
            if (position.coords.latitude && position.coords.longitude) {
              // FIXME This doesn't seem to be storing the lat/lng along with these
              window.analytics.track('User Locations', {
                context_latitude: position.coords.latitude,
                context_longitude: position.coords.longitude,
                user_id: user.id,
              });
            }
          }
        }
      }}
      render={({
        fetchingPosition,
        getCurrentPosition,
        position: { coords = state.coordinates } = {},
        error,
      }) => (
        <div>
          {isMobile
            ? (
              <div>
                <GeoLocationSearch
                  lazy
                  placeholder={messages[language]?.change_city || 'Change city or zip...'}
                  className="venue-geolocation-search"
                  // style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px' }}
                  wrapperStyle={{ position: 'sticky', top: insetTop, zIndex: 999 }}
                  rightButtons={[
                    <MaterialButton
                      aria-label="Get current location"
                      onClick={() => {
                        if (window.Capacitor.isNative) {
                          NativeGeolocation.getCurrentPosition().then((position) => {
                            setPermissionUpdated(true);
                            setState({ loading: true });
                            setCoordinates(position.coords);
                          }).catch(() => {
                            // TODO How to know they denied permission?
                            // openAppSettings();
                          });
                        } else if (Transducer.isNative()) {
                          window.parent.postMessage({
                            type: 'on_location_request',
                          }, '*');
                        } else {
                          getCurrentPosition();
                        }
                      }}
                    >
                      <Icon name="gps-fixed" vendor="material" />
                    </MaterialButton>,
                    <MaterialButton aria-label="Filter venues" onClick={toggleBottomSheet}><Icon name="sliders" /></MaterialButton>,
                  ]}
                  onSelect={(suggestion) => {
                    console.log(`Selected location: ${JSON.stringify(suggestion)}`);
                    if (suggestion.geometry) {
                      console.log(suggestion.geometry);
                      setState({
                        loading: true,
                      });
                      setSelectedLocation(suggestion);
                      setCoordinates(suggestion.geometry.location);
                    }
                  }}
                />
                {/* Unable to access current location. Please enable permissions. */}
                {((Transducer.isNative() && nativeError) || (!Transducer.isNative() && error)) && !fetchingPosition && !permissionUpdated && (
                  <div>
                    <Alert severity="error">{'To get your current position, you must first enable the location permission.'}</Alert>
                    {/* TODO Button to open settings on native Android - prompt for permission on web */}
                  </div>
                )}
                {(((Transducer.isNative() && !nativeError) || (!Transducer.isNative() && !error)) || permissionUpdated || state.loading) && (
                  <MapListingContent
                    venues={venues}
                    loading={state.loading}
                    coordinates={coordinates}
                    // onLocationUpdated={(coord) => {
                    //   setState({
                    //     coordinates: coords,
                    //   }, () => {
                    //     updateVenues();
                    //   });
                    // }}
                  />
                )}
                { renderFilterSheet() }
              </div>
            ) : (
              <div>
                <div style={{ marginBottom: 10 }}>
                  <InputGroup style={{ maxWidth: 300 }}>
                    <GooglePlacesAutocomplete
                      // apiKey={config.google.api_key}
                      className="form-control"
                      placeholder="Change location"
                      onPlaceSelected={(place) => {
                        setState({
                          loading: true,
                        });
                        const latitude = place.geometry?.location.lat();
                        const longitude = place.geometry?.location.lng();
                        place.geometry.location.latitude = latitude;
                        place.geometry.location.longitude = longitude;
                        console.log('Selected coordinates:');
                        console.log({ latitude, longitude });
                        setSelectedLocation(place);
                        setCoordinates({ latitude, longitude });
                      }}
                      types={['locality']}
                      fields={[
                        'address_components',
                        'geometry.location',
                        'place_id',
                      ]}
                    />
                    <InputGroupAddon addonType="append">
                      <Button onClick={getCurrentPosition} className="current-location-btn"><Icon name="gps-fixed" vendor="material" /></Button>
                    </InputGroupAddon>
                  </InputGroup>
                  <div style={{ position: 'absolute', right: 30, top: 77 }}>
                    { renderOptions() }
                  </div>
                </div>
                <MapListingContent
                  venues={venues}
                  loading={state.loading}
                  coordinates={coordinates}
                  // onLocationUpdated={(coords) => {
                  //   setState({
                  //     coordinates: coords,
                  //   }, () => {
                  //     updateVenues();
                  //   });
                  // }}
                />
              </div>
            )}
        </div>
      )}
    />
  );
}

function mapStateToProps(state) {
  return {
    auth: state.get('auth').toJS(),
  };
}

export default connect(mapStateToProps)(MapListing);
