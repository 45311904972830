import React, { useEffect, useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import Geolocation from 'react-geolocation';
import GooglePlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import AppBar from '@material-ui/core/AppBar';
import { darken } from '@material-ui/core';
import Icon from '../Icon';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  search: {
    margin: '8px 20px',
    position: 'relative',
    display: 'flex',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: darken(theme.palette.common.white, 0.10),
    '&:hover': {
      backgroundColor: darken(theme.palette.common.white, 0.05),
    },
    '&:focus': {
      backgroundColor: darken(theme.palette.common.white, 0.05),
    },
    marginRight: theme.spacing(2),
    // marginLeft: 0,
    // width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
    border: 'none',
    background: 'transparent',
  },
}));

function GeoLocationSearch(props) {
  const classes = useStyles();

  const [query, setQuery] = useState(null);
  const [locations, setLocations] = useState([]);

  const { rightButtons } = props;

  useEffect(() => {
    updateResults();
  }, []);

  const updateResults = () => {

  };

  const renderRightButtons = () => {
    console.log('Rendering right buttons...');
    // TODO Can we pass aria-label in RightButton and use it here? RightButton.props.ariaLabel
    return rightButtons.map((button) => (
      <IconButton
        aria-label=""
        edge="end"
        color="inherit"
        style={{
          width: 44,
          padding: 6,
          float: 'right',
          marginRight: 5,
          height: 40,
        }}
      >
        { button }
      </IconButton>
    ));
  };

  return (
    <div className={props.className} style={props.wrapperStyle}>
      <Geolocation
        lazy={props.lazy}
        render={({
          fetchingPosition,
          position: { coords: { latitude, longitude } = {} } = {},
        }) => (
          <div>
            <GooglePlacesAutocomplete
              placeholder={props.placeholder || 'Enter city or zip code...'}
              autocompletionRequest={{
                // FIXME This isn't making a difference in the results - we need geometry.location
                fields: [
                  // 'address_components',
                  'geometry.location',
                  // 'place_id',
                  'formatted_address',
                ],
                types: ['(regions)'],
              }}
              renderInput={(innerProps) => (
                <AppBar
                  position="static"
                  color="white"
                  style={{ ...props.style }}
                >
                  <div className={classes.search}>
                    <div className={classes.searchIcon}>
                      <Icon name="search" />
                    </div>
                    <input
                      className={classes.inputRoot}
                      aria-label="Search places"
                      {...innerProps}
                    />
                    { rightButtons && renderRightButtons() }
                  </div>
                </AppBar>
              )}
              loader={
                <List
                  style={{
                    position: 'absolute',
                    minHeight: 'calc(100vh - 168px)',
                    width: '100%',
                    zIndex: 1001,
                    backgroundColor: '#efefef',
                  }}
                >
                  <div style={{ padding: 20 }}>{'Loading...'}</div>
                </List>
              }
              renderSuggestions={(active, suggestions, onSelectSuggestion) => (
                <List
                  style={{
                    position: 'absolute',
                    minHeight: 'calc(100vh - 168px)',
                    width: '100%',
                    zIndex: 1001,
                    backgroundColor: '#efefef',
                  }}
                >
                  { latitude && longitude
                    ? (
                      <ListItem
                        alignItems="flex-start"
                        onClick={() => props.onSelect({
                          geometry: {
                            location: {
                              latitude,
                              longitude,
                            },
                          },
                        })}
                      >
                        <ListItemText
                          primary="Current Location"
                        />
                      </ListItem>
                    ) : null }
                  {suggestions.map((suggestion) => (
                    <ListItem
                      alignItems="flex-start"
                      onClick={(event) => onSelectSuggestion(suggestion, event)}
                    >
                      <ListItemText
                        primary={suggestion.description}
                      />
                    </ListItem>
                  ))}
                </List>
              )}
              onSelect={async (suggestion) => {
                // TODO This is temporary - should always be able to get results for geometry here
                if (!suggestion.geometry) {
                  const status = await geocodeByPlaceId(suggestion.place_id);
                  console.log('Status:');
                  console.log(status);

                  const result = status[0];

                  if (result) {
                    suggestion.geometry = {
                      location: {
                        latitude: result.geometry.location.lat(),
                        longitude: result.geometry.location.lng(),
                      },
                    };
                  } else {
                    suggestion.geometry = {
                      location: {
                        latitude: 34.165699,
                        longitude: -81.348961,
                      },
                    };
                  }
                } else {
                  suggestion.geometry = {
                    location: {
                      latitude: suggestion.geometry.location.lat,
                      longitude: suggestion.geometry.location.lng,
                    },
                  };
                }
                console.log('Selected suggestion...');
                console.log(suggestion);
                props.onSelect(suggestion);
              }}
            />
            {/* FIXME Not sure why it won't re-render after getting the location */}
            {/* <p>Fetching Position: {fetchingPosition ? "YES" : "NO"}</p> */}
            {/* <p>{latitude}</p> */}
            {/* <p>{longitude}</p> */}
          </div>
        )}
      />
    </div>
  );
}

GeoLocationSearch.propTypes = {
  className: PropTypes.string,
  wrapperStyle: PropTypes.object,
  lazy: PropTypes.bool,
  placeholder: PropTypes.string,
  rightButtons: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
};

GeoLocationSearch.defaultProps = {
  className: null,
  wrapperStyle: {},
  lazy: false,
  placeholder: 'Start typing...',
  rightButtons: [],
};

export default GeoLocationSearch;
