import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import axios from 'axios';
import { isMobile } from 'mobile-device-detect';
import { Skeleton } from '@material-ui/core';
import MobileSearchBar from '../MobileSearchBar';
import VenueEditor from '../../pages/VenueEditor';
import Venue from '../../models/Venue';
import { useLocation } from '../../utils/hooks/useLocation';

const env = process.env.NODE_ENV || 'development';
const config = require('../../../config/config.json')[env];

function LocationSearch(props) {
  const [query, setQuery] = useState(null);
  const coordinates = useLocation();
  const [venues, setVenues] = useState([]);
  const [showVenueModal, setShowVenueModal] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if ((coordinates.latitude && coordinates.longitude) || query) {
      updateResults();
    }
  }, [query, coordinates]);

  const updateResults = () => {
    if (query && query.length > 0) {
      axios.get(`${config.apiEndPoint}/venues/search`, {
        params: {
          q: query,
          limit: 30,
          latitude: coordinates.latitude,
          longitude: coordinates.longitude,
          smokeShopsOnly: true, // TODO Use a prop to determine this
        },
      }).then((response) => {
        setLoading(false);
        setVenues(response.data);
      }).catch((err) => {
        console.log(err);
      });
    } else {
      axios.get(`${config.apiEndPoint}/venues/nearby`, {
        params: {
          limit: 30,
          sort: 'distance',
          radius: 40000, // Can probably decrease this - this is really meant for if they're in the place
          latitude: coordinates.latitude,
          longitude: coordinates.longitude,
          buy: props.smokeOnly ? null : props.buyOnly,
          smoke: props.buyOnly ? null : props.smokeOnly,
        },
      }).then((response) => {
        setLoading(false);
        setVenues(response.data);
      }).catch((err) => {
        console.log(err);
      });
    }
  };

  return (
    <div>
      <MobileSearchBar
        placeholder="Search venues…"
        onQueryChange={(newQuery) => setQuery(newQuery)}
      />
      <List style={{ ...props.listStyle }}>
        {loading && (
          <ListItem>
            <Skeleton variant="circular" style={{ height: 40, width: 40 }} />
            <div style={{ width: '100%', marginLeft: 12 }}><Skeleton /></div>
          </ListItem>
        )}
        {venues.map((venue) => (
          <ListItem
            alignItems="flex-start"
            onClick={() => props.onSelect(venue)}
          >
            <ListItemAvatar>
              <Avatar alt={venue.name} src={Venue.getImage(venue)}>{venue.name.charAt(0)}</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={venue.name}
              secondary={(
                <>
                  {(venue.location && venue.location.formatted_address)
                    ? venue.location.formatted_address
                    : venue.website}
                </>
              )}
            />
          </ListItem>
        ))}
        {(!venues || venues.length === 0) && !loading && (
          <ListItem
            key="venue-item-not-found"
            onClick={() => {
              setShowVenueModal(true);
            }}
          >
            <div style={{ width: '100%', textAlign: 'center', padding: 12, cursor: 'pointer' }}>
              <span>
                {"Can't find the venue?"}
                {isMobile ? <button style={{ marginLeft: 10 }} className="btn btn-outline-grey">Add New Venue</button> : <span style={{ color: '#d6c290', marginLeft: 8 }}>Click here to add</span>}
              </span>
            </div>
          </ListItem>
        )}
      </List>
      <VenueEditor
        // venue={selectedVenue}
        open={showVenueModal}
        toggle={() => setShowVenueModal(!showVenueModal)}
        onClose={() => setShowVenueModal(false)}
        onSaved={(venue) => props.onSelect(venue)}
      />
    </div>
  );
}

LocationSearch.propTypes = {
  buyOnly: PropTypes.bool,
  smokeOnly: PropTypes.bool,
  listStyle: PropTypes.object,
};

LocationSearch.defaultProps = {
  buyOnly: false,
  smokeOnly: false,
  listStyle: {},
};

export default LocationSearch;
