import './style.scss';
import '@fancyapps/fancybox';
import React, { PureComponent } from 'react';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { Card, Row, Col } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import Rating from 'react-rating';
import { isMobile } from 'mobile-device-detect';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Avatar from '@material-ui/core/Avatar';
import { connect } from 'react-redux';
import Skeleton from '@material-ui/core/Skeleton';
import Hashids from 'hashids/cjs';
import TabPanel from '../TabPanel';
import Placeholder from '../../../config/placeholder.config';
import { mapStylesDark, mapStylesLight } from './index';
import { renderAvailability } from '../../pages/VenueDetails';
import { Resize } from '../../utils/imageUtils';
import DynamicAd from '../dynamic-ad';
import { EmptySquareStar, FilledSquareStar } from '../Ratings';
const hashids = new Hashids('', 12);

class MapListingContent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      selectedZoom: isMobile ? 9 : 10,
      selectedCenter: null,
      selectedCurrentLocation: null,
      selectedVenue: null,
      showInfoWindow: false,
      selectedIndex: -1,
      mapMarkers: {},
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const nextCoordinates = nextProps.coordinates;
    const { coordinates } = this.props;
    console.log('Coordinate values:');
    console.log(nextCoordinates);
    console.log(coordinates);
    if (nextCoordinates && (!coordinates || nextCoordinates.latitude !== coordinates.latitude || nextCoordinates.longitude !== coordinates.longitude)) {
      const coords = { lat: nextCoordinates.latitude, lng: nextCoordinates.longitude };
      this.setState({
        selectedCenter: coords,
        selectedCurrentLocation: coords,
      });
    }
  }

  formatDistance = (venue) => {
    const distance = (venue.distance / 1706.3).toFixed(1);
    const units = 'mi'; // TODO Switch based on locale
    return `${distance}${units}`;
  };

  getImage = (venue) => {
    let imageUrl = null; // Placeholder.venue;
    if (venue.profile_image_url) {
      imageUrl = venue.profile_image_url;
      if (imageUrl.indexOf('://') === -1) {
        imageUrl = Resize.size(`https://${imageUrl}`, { height: 100, width: 100, cropType: 'crop' });
      }
      return Resize.size(imageUrl, { height: 100, width: 100, cropType: 'crop' });
    }
    if (venue.images) {
      for (let i = 0; i < venue.images.length; i++) {
        if (venue.images[i].external_id_type === 'google') {
          imageUrl = Resize.size(venue.images[i].url, { height: 100, width: 100, cropType: 'crop' });
          break;
        }
      }
    }
    return imageUrl;
  };

  showVenueDetail = (venue) => {
    if (venue && venue.id) {
      window.analytics?.track('Venue Clicks', {
        venue_id: venue.id,
        user_id: this.props.auth && this.props.auth.user && this.props.auth.user.id,
      });
      this.props.history.push({ pathname: `/venues/${hashids.encode(venue.id)}`, state: { venue } });
    }
  };

  markerLoadHandler = (marker, venue) => {
    const { mapMarkers } = this.state;
    mapMarkers[venue.id] = marker;
    this.setState({
      mapMarkers,
    });
  };

  markerClickHandler = (event, venue) => {
    // Used to close the previous info window
    this.setState({
      selectedVenue: null,
      showInfoWindow: false,
    });

    this.setState({
      selectedVenue: venue,
      showInfoWindow: true,
    });
  };

  toggleTab = (event, index) => {
    this.setState({
      activeTab: index,
    });
  };

  renderMap = () => {
    const {
      showInfoWindow,
      selectedVenue,
      mapMarkers,
      selectedZoom,
      selectedCenter,
      selectedCurrentLocation,
      selectedIndex,
    } = this.state;
    const { venues, settings } = this.props;
    const { night_mode } = settings;
    return (
      <GoogleMap
        mapContainerClassName="rui-gmap"
        options={{
          zoom: selectedZoom,
          scrollwheel: false,
          mapTypeControl: false,
          streetViewControl: false,
          gestureHandling: 'greedy',
          // TODO Show "current location" blue dot at center
          center: selectedCenter,
          zoomControl: true,
          zoomControlOptions: {
            // position: window.google.maps.ControlPosition.LEFT_TOP,
          },
          styles: night_mode ? mapStylesDark : mapStylesLight,
        }}
        onLoad={(map) => {
          // console.log('Loaded map:');
          // console.log(map);
          this.mapRef = map;
        }}
        onIdle={() => {
          if (this.mapRef) {
            const center = this.mapRef.getCenter();
            // console.log('New center:');
            // console.log(center);
            if (!selectedCenter || center.lat !== selectedCenter.lat || center.lng !== selectedCenter.lng) {
              this.setState({ selectedCenter: center });
            }
          }
        }}
        onZoomChanged={() => {
          if (this.mapRef) {
            // console.log('Zoomed to:');
            // console.log(this.mapRef.getZoom());
            const zoom = this.mapRef.getZoom();
            if (zoom !== selectedZoom) {
              this.setState({ selectedZoom: zoom });
            }
          }
        }}
      >
        {
          venues.map((venue, index) => (
            <Marker
              key={venue.id}
              onLoad={(marker) => this.markerLoadHandler(marker, venue)}
              onClick={(event) => this.markerClickHandler(event, venue)}
              position={{
                lat: venue.location.coordinate && venue.location.coordinate.latitude,
                lng: venue.location.coordinate && venue.location.coordinate.longitude,
              }}
              icon={{
                url: Placeholder.location.marker,
                // https://stackoverflow.com/questions/12093327/how-to-align-the-icon-of-a-marker-in-google-map
                size: selectedIndex === index ? new google.maps.Size(46, 46) : new google.maps.Size(42, 42),
                origin: new google.maps.Point(0, 0),
                anchor: selectedIndex === index ? new google.maps.Point(46, 46) : new google.maps.Point(42, 42),
                scaledSize: selectedIndex === index ? new google.maps.Size(46, 46) : new google.maps.Size(42, 42),
              }}
              name={venue.name}
              zIndex={selectedIndex === index ? 99999999 : null}
            />
          ))
        }

        {selectedCenter && (
          <Marker
            key="current-location"
            position={selectedCurrentLocation}
            icon={{
              url: Placeholder.location.current,
              size: new google.maps.Size(30, 30),
              scaledSize: new google.maps.Size(30, 30),
            }}
            name="My Location"
          />
        )}

        {/* https://codesandbox.io/s/react-google-maps-api-ir5ks */}
        {showInfoWindow && selectedVenue && (
          <InfoWindow
            anchor={mapMarkers[selectedVenue.id]}
            onCloseClick={() => {
              this.setState({
                showInfoWindow: false,
              });
            }}
          >
            <div>
              <Row>
                <Col style={{ maxWidth: 94 }}>
                  <Avatar src={this.getImage(selectedVenue)} style={{ height: 65, width: 65 }}><span style={{ fontSize: 24 }}>{selectedVenue.name.charAt(0)}</span></Avatar>
                  <div className="text-center">
                    <Link to={`/venues/${selectedVenue.hash_id}?tab=photos`} style={{ fontSize: 10, marginTop: 3 }}>View More Photos</Link>
                  </div>
                </Col>
                <Col>
                  <h6>{selectedVenue.name}</h6>
                  <div style={{ fontSize: 12 }}>{selectedVenue.location.formatted_address}</div>
                </Col>
              </Row>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    );
  };

  renderLoadingCard = () => (
    <li className="list-group-item" style={{ paddingTop: 16, display: 'block' }}>
      <div className="media media-filled">
        <span className="media-img bg-transparent" style={{ width: 70 }}>
          <Skeleton variant="circular" height={70} width={70} style={{ height: 70, width: 70 }} />
        </span>
        <span className="media-body">
          <span className="media-title">
            <Skeleton style={{ width: '100%' }} />
          </span>
          <span style={{ fontSize: 12 }} className="media-subtitle"><Skeleton /></span>
          <div style={{ marginTop: 10 }}>
            <Skeleton style={{ width: '100%' }} />
            <Skeleton style={{ width: '100%' }} />
          </div>
        </span>
      </div>
    </li>
  );

  renderVenueCard = (venue, index) => {
    // FIXME Using virtual lists, can't this be inaccurate? Disabling for now to avoid issues with Segment
    // window.analytics.track('Venue Impressions', {
    //   venue_id: venue.id,
    //   impression_type: 'listing',
    //   user_id: this.props.auth && this.props.auth.user && this.props.auth.user.id,
    // });
    return (
      <li
        className="list-group-item"
        onMouseEnter={() => {
          this.setState({
            selectedIndex: index,
          });
          this.setState({
            selectedVenue: null,
            showInfoWindow: false,
          });
          setTimeout(() => {
            this.setState({
              selectedVenue: venue,
              showInfoWindow: true,
            });
          }, 100);
        }}
        onMouseLeave={() => {
          this.setState({
            selectedIndex: -1,
          });
          this.setState({
            selectedVenue: null,
            showInfoWindow: false,
          });
        }}
      >
        <div className="media media-filled" onClick={() => this.showVenueDetail(venue)} style={{ cursor: 'pointer', padding: 10 }}>
          <span className="media-img bg-transparent">
            <Avatar variant="rounded" src={this.getImage(venue)} style={{ height: 65, width: 65 }}><span style={{ fontSize: 24 }}>{venue.name.charAt(0)}</span></Avatar>
          </span>
          <span className="media-body">
            <span className="media-title">
              {venue.name}
            </span>
            <span style={{ fontSize: 12 }} className="media-subtitle">{venue.location.formatted_address}</span>
            <div style={{ display: 'flex', width: '100%' }}>
              <span className="venue-card-availability" style={{ marginBottom: -20 }}>
                {renderAvailability(venue)}
              </span>
            </div>
            <div style={{ marginTop: 10 }}>
              <Rating
                initialRating={venue.average_rating || 0}
                emptySymbol={<EmptySquareStar />}
                fullSymbol={<FilledSquareStar />}
                fractions={2}
                readonly
                style={{ color: 'rgb(214, 194, 144)', marginRight: 6 }}
              />
              <span className="media-rating">{`(${venue.total_ratings || 0} ratings)`}</span>
              <span style={{ float: 'right' }}>{this.formatDistance(venue)}</span>
            </div>
          </span>
        </div>
      </li>
    );
  }

  renderList = () => {
    const { venues, loading } = this.props;
    return (
      <div>
        { loading && [1, 2, 3, 4].map(() => this.renderLoadingCard())}
        { !loading && (venues.length === 0) && (
          <div style={{ textAlign: 'center', padding: 24 }}>No venues found for this search</div>
        )}
        {!loading && venues.length > 0 && (
          <li className="list-group-item">
            <div className="media media-filled" onClick={() => {/* TODO ? How to handle this? Pass data-venue-id hash id? */}} style={{ cursor: 'pointer', padding: 10 }}>
              <DynamicAd zoneId="103055" />
            </div>
          </li>
        )}
        { !loading && venues.map((venue, index) => this.renderVenueCard(venue, index))}
        <li className="list-group-item" style={{ textAlign: 'center', padding: 16 }}>
          {"Can't find the venue you're looking for? Try searching for it above"}
        </li>
      </div>
    );
  };

  render() {
    const { activeTab } = this.state;
    const { coords } = this.props;
    // FIXME How do we make sure this gets called when the user allows permissions?
    if (coords) {
      console.log('Got coords:');
      console.log(coords);
      // onLocationUpdated(coords);
    }
    return (
      <div>
        {isMobile
          ? (
            <div>
              <div>
                <Tabs
                  value={activeTab}
                  onChange={this.toggleTab}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="Nearby Venue Listings"
                >
                  <Tab label="List" />
                  <Tab label="Map" />
                </Tabs>
              </div>
              <div>
                <TabPanel value={activeTab} index={0}>
                  { this.renderList() }
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                  { this.renderMap() }
                </TabPanel>
              </div>
            </div>
          ) : (
            <Card>
              <Row style={{ margin: 0 }}>
                <Col md={8}>
                  {this.renderMap()}
                </Col>
                <Col md={4} style={{ maxHeight: '80vh', overflowY: 'scroll', padding: 0 }}>
                  {this.renderList()}
                </Col>
              </Row>
            </Card>
          )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    settings: state.get('settings'),
  };
}

export default withRouter(connect(mapStateToProps)(MapListingContent));
